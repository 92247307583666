import React, { Component } from 'react';
import {graphql} from 'gatsby'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha'
import zparse from 'helpers/parse';
import Layout from 'components/layout/internal';
import Select from 'shared/form/select/';
import SEO from 'shared/seo'
import style from 'components/layout/internal.module.scss';
import formStyle from './requestAppointment.module.scss';
import TextInput from 'shared/form/textinput/';

export default class RequestAppointment extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}get${this.page.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						content: data[0].content.main,
						title: data[0].pageTitle,
						meta: data[0].meta,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);
		this.page = this.props.data.allContentPage.edges.filter(({node}) => node.uri === this.props.location.pathname).map(item => item.node).shift();
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.timerID = null;
		let specialtyOptions = [];
		if (props.data && props.data.allPhysiciansSpecialty && props.data.allPhysiciansSpecialty.edges.length) {
			props.data.allPhysiciansSpecialty.edges.forEach(specialty => {
				specialtyOptions.push(specialty.node.name);
			});
		}
		if (specialtyOptions.length) {
			specialtyOptions.sort();
		}

		this.formSchema = Yup.object().shape({
			firstName: Yup.string()
				.required('Please enter your first name.'),
			lastName: Yup.string()
				.required('Please enter your last name.'),
			phone: Yup.string()
				.required('Please enter a phone number.'),
			specialty: Yup.string()
				.required('Please select a specialty'),
			insProvider: Yup.string()
				.required('Please enter an Insurance Provider.'),
		});
		this.state = {
			content: (this.page ? this.page.content.main : 'Page Not Found'),
			title: (this.page ? this.page.pageTitle : 'Page Not Found'),
			meta: (this.page ? this.page.meta : ''),
			recaptcha: '',
			recaptchaError: false,
			success: false,
			specialtyOptions: specialtyOptions,
		}
		this.initialValues = {
			env: 'REQ_APPT_FORM',
			mailto: 'email1',
			firstName: '',
			lastName: '',
			phone: '',
			specialty: '',
			insProvider: '',
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	componentWillUnmount() {
		if (this.timerID !== null) clearTimeout(this.timerID);
		this.timerID = null;
	}

	submitForm(values, { setSubmitting }) {
		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, { recaptcha: this.state.recaptcha })),
		};
		fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function (response) {
				if (!response.ok) {
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID) clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.setState({
						success: false,
					});
					this.timerID = null;
				}, 3000);

			})
			.catch(function (err) {
				alert(err);
			})
			.finally(function () {
				setSubmitting(false);
			});
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<>
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-offset-2 medium-8 end">
							<h1 className={style.pageTitle}>{this.state.title}</h1>
							<section className={[style.mainContent, "main-content"].join(' ')}>
								{zparse(this.state.content)}
								<div className="grid-x grid-margin-x">
									<div className="cell small-12">
										<Formik
											initialValues={this.initialValues}
											validationSchema={this.formSchema}
											onSubmit={this.submitForm}
										>
											{({ setFieldValue, isSubmitting }) => (
												<Form id="requestAppointmentForm" method="post" encType="multipart/form-data">
													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" name="firstName" label="First Name" component={TextInput}/>
														<Field className="cell" name="lastName" label="Last Name" component={TextInput}/>
														<Field className="cell" name="phone" label="Phone" component={TextInput}/>
														<Field className="cell" name="specialty" label="Specialty" options={this.state.specialtyOptions} placeholder="-- Please select --" component={Select}/>
														<Field className="cell" name="insProvider" label="Insurance Provider" component={TextInput}/>
													</div>
													<div style={{ marginTop: '1rem', marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} /></div><div className={ [formStyle.error, (this.state.recaptchaError ? formStyle.active : '')].join(' ') }>You must complete the ReCAPTCHA</div></div>
													<button className="button primary" type="submit" disabled={isSubmitting}>Submit Appointment Request</button>
													{ <div className={ [formStyle.success, (this.state.success ? formStyle.showSuccess : '')].join(' ') }>Thank you for contacting Heritage Medical Associates. Someone will receive your message shortly.</div> }
												</Form>
											)}
										</Formik>
									</div>
								</div>
							</section>
						</div> 
					</div>
				</div>
			</Layout>
			<SEO title={this.state.title} meta={this.state.meta} />
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allContentPage(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
	        pageTitle
	      }
	    }
	  }
	  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	      	id
	      	name
	      	uri
	      }
	    }
	  }
	}
`