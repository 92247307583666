import React from "react"
import parse, { domToReact } from 'html-react-parser';
import Link from 'shared/link'
import Accordion from 'components/accordion';

const parserOptions = {
	htmlparser2: {
		lowerCaseTags: false
	},
	replace: ({ name, attribs, children }) => {
		switch (name) {
			case "a":
				attribs.className = attribs.class;
				if (attribs.href && (attribs.href.indexOf("tel") !== -1 || attribs.href.indexOf("mail") !== -1)) attribs.external = true;
				return <Link {...attribs}>{ domToReact(children) }</Link>
		}
		if (attribs && attribs.hasOwnProperty("class")) {
			if (/accordion/g.test(attribs.class)) {
				if (children) {
					return (
						<>
							{ children.map((child) => {
								if (child.type === "tag" && child.name === "li" && child.hasOwnProperty("children")) {
									let title;
									let body;
									let { children } = child;
									const accordionParts = children.filter((c) => { if (c.type === "tag") return c });

									title = domToReact(accordionParts[0].children);
									body = domToReact(accordionParts[1].children);
									if (Array.isArray(body)) {
										const bodyParts = body.filter((b) => { if (b.hasOwnProperty('type')) return b});
										if (bodyParts.length) {
											bodyParts.forEach((part, index) => {
												bodyParts[index] = `<${part.type}>${part.props.children}</${part.type}>`;
											})
										}
										body = bodyParts.join('');
									}

									return <Accordion title={title} body={body} />;
								}
								return domToReact(child);
							}) }
						</>
					)
				}
			}
		}
	}
};

export default function zparse(str, options = parserOptions) {
	if (!str) return "";
	return parse(str, options);
}